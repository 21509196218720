/* 목록 css */
.list h1 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.list h2 {
  font-size: 1.2rem;
}

.list h2:not(:first-child) {
  margin-top: 3rem;
}
.list h2:before {
  display: inline-block;
  content: "#";
}
.list h3 {
  font-size: 1rem;
  margin-top: 1rem;
}
.list ul {
  font-size: 1rem;
  margin-bottom: 1rem;
}
.list ul li {
  padding-bottom: 0.5rem;
}
.list ul a {
  color: gray;
  text-decoration: underline;
}
.list .txtBak {
  color: darkgrey;
}
