@charset "UTF-8";
/* layout */
html, body {
  height: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

.txtBr {
  display: block;
}

.blind {
  color: transparent;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  line-height: 0;
}

.wrapper {
  overflow: hidden;
}

/* Main */
.mart .header img {
  width: 100%;
  height: auto;
}

.mart .header.type2 {
  margin-bottom: 0.5625rem;
}

.dateBox {
  width: 55%;
  margin: 4% auto 3.2%;
}

.dateBox-type2 {
  margin: 0 auto 3.2%;
}

.iconListWrap {
  margin: 0 2%;
  overflow: hidden;
}

.iconListWrap .list {
  overflow: hidden;
  text-align: center;
}

.iconListWrap .list.obj02 .icoBtn {
  width: 49%;
}

.iconListWrap .list .icoBtn {
  width: 32.3%;
  display: inline-block;
  margin: 0.1875rem 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.iconListWrap .list .icoBtn img {
  width: 100%;
}

.iconListWrap.v2 .icoBtn {
  width: 100%;
  margin: 0;
}

.iconListWrap.v2 .list:nth-child(1) {
  float: left;
  width: 65.74074074074074%;
}

.iconListWrap.v2 .list:nth-child(2) {
  float: left;
  width: 34.25925925925926%;
}

.iconListWrap.v2 .list:nth-child(3) {
  clear: both;
}

.iconListWrap.v3 .icoBtn {
  float: left;
  margin: 0;
}

.iconListWrap.v3 .icoBtn:nth-child(1), .iconListWrap.v3 .icoBtn:nth-child(3) {
  width: 34.07407407407407%;
}

.iconListWrap.v3 .icoBtn:nth-child(2) {
  width: 31.85185185185185%;
}

.iconListWrap.v3 .icoBtn img {
  vertical-align: top;
}

.iconListWrap.v3 .list:first-child .icoBtn {
  width: 50%;
}

.iconListWrap.v4 .icoBtn {
  width: 50%;
  margin: 0;
  float: left;
}

.iconListWrap.v4 .list:nth-child(3) .icoBtn:nth-child(1) {
  width: 35.55555555555556%;
}

.iconListWrap.v4 .list:nth-child(3) .icoBtn:nth-child(2) {
  width: 31.48148148148148%;
}

.iconListWrap.v4 .list:nth-child(3) .icoBtn:nth-child(3) {
  width: 32.96296296296296%;
}

.iconListWrap.v5 .icoBtn {
  float: left;
  margin: 0;
}

.iconListWrap.v5 .icoBtn:nth-child(1), .iconListWrap.v5 .icoBtn:nth-child(3) {
  width: 34.07407407407407%;
}

.iconListWrap.v5 .icoBtn:nth-child(2) {
  width: 31.85185185185185%;
}

.iconListWrap.v5 .icoBtn img {
  vertical-align: top;
}

.iconListWrap.v6 {
  margin: -0.5625rem 0 0;
}

.iconListWrap.v6 .icoBtn {
  float: left;
  margin: 0;
}

.iconListWrap.v6 .icoBtn:nth-child(1) {
  width: 34.25925925925926%;
}

.iconListWrap.v6 .icoBtn:nth-child(2) {
  width: 31.66666666666667%;
}

.iconListWrap.v6 .icoBtn:nth-child(3) {
  width: 34.07407407407407%;
}

.iconListWrap.v6 .icoBtn img {
  vertical-align: top;
}

.bannerBox {
  margin: 0 auto;
  text-align: center;
}

.bannerBox a {
  display: block;
}

.bannerBox.fix {
  padding: 0 3%;
}

.bannerBox.fix:last-child {
  margin-top: 0.5625rem;
  margin-bottom: 1.125rem;
}

.openStore {
  overflow: hidden;
  margin: 0.5625rem 0 0.5rem;
  padding: 0 3%;
  text-align: center;
}

.openStore + .openStore {
  padding: 0 3%;
}

.openStore > a {
  display: block;
}

/* 팝업영역 */
.bannerPopBox {
  /* page */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}

.bannerPopBox .bannerPop {
  /* swiper.min */
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.bannerPopBox .bannerPop .banner {
  /* swiper.min */
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  /* page css */
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.bannerPopBox .bannerPop .banner a {
  display: block;
}

.bannerPopBox .bannerPop .banner .bannerImg {
  width: 92%;
  margin: 0 2%;
}

@keyframes btnClose {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes btnClose {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.btnPop {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 999;
  margin-top: -55%;
  /* margin-left:35%; */
}

.btnPop.close button {
  width: 3.3125rem;
  height: 3.4375rem;
  cursor: pointer;
  background-color: transparent;
  background-image: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/btn_pop_close.png");
  background-size: 100%;
  animation-name: btnClose;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
}

/* 카테고리 상품페이지 */
.subCont {
  overflow: auto;
  position: relative;
}

.subCont img {
  width: 100%;
  height: auto;
  font-size: 0.75rem;
  color: #666;
}

.subCont a {
  display: block;
  width: 100%;
}

.headerCont {
  height: 2.625rem;
  padding-top: 0.4375rem;
  background-color: #e50015;
}

.headerCont .logoTit {
  position: relative;
  background: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/logo_sub02.png") no-repeat;
  background-position: 50%;
  background-size: 6.125rem;
}

.headerCont .logoTit > :first-child {
  font-size: 1rem;
  opacity: 0;
}

.headerCont2 {
  height: 2.625rem;
  padding-top: 0.4375rem;
  background-color: #ffffff;
}

.headerCont2 .logoTit,
.headerCont2 .logoTit-vic {
  position: relative;
  height: 2.3125rem;
  text-indent: -10000rem;
  background-repeat: no-repeat;
  background-position: 50%;
}

.headerCont2 .logoTit {
  background-image: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/top_ci.png");
  background-size: 6.125rem;
}

.headerCont2 .logoTit > :first-child {
  font-size: 1rem;
  opacity: 0;
}

.headerCont2 .logoTit-vic {
  background-image: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/logo_vic.png");
  background-size: 8.75rem;
}

.btnMain {
  z-index: 50;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  text-align: right;
}

.btnMain a {
  display: inline-block;
  margin: 0.5rem 0.625rem;
  width: 5.375rem;
  height: 1.8125rem;
  background: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/btn_main.png") 50% no-repeat;
  background-size: 100%;
}

.btnMain a .txt {
  display: inline-block;
  opacity: 0;
}

.btnMain2 {
  z-index: 50;
  cursor: pointer;
  position: fixed;
  top: 0;
  right: 0;
  text-align: right;
}

.btnMain2 a {
  display: inline-block;
  margin: 0.5rem 0.625rem;
  width: 5.375rem;
  height: 1.8125rem;
  background: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/btn_go_home.png") 50% no-repeat;
  background-size: 100%;
}

.btnMain2 a .txt {
  display: inline-block;
  opacity: 0;
}

.footer {
  padding: 0.9375rem 0.5rem 0.5rem;
  background-color: #ebebeb;
  text-align: center;
}

.footer > :first-child {
  width: 60%;
  margin: 0 auto 0.6875rem;
}

/* 대체텍스트 markup */
.alternate {
  position: absolute;
  top: -9999px;
  left: -9999px;
  height: 0;
  line-height: 0;
  overflow: hidden;
  font-size: 0;
  text-align: left;
}

/* swiper css */
.swiper-button-next {
  width: 2.6875rem;
  height: 2.6875rem;
  position: fixed;
  background-size: 100%;
  background-image: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/btn_next.png");
}

.swiper-button-prev {
  width: 2.6875rem;
  height: 2.6875rem;
  position: fixed;
  background-size: 100%;
  background-image: url("https://imagecoupon.lottemart.com/lmscp/leaflet/resource/image/common/btn_prev.png");
}
